import React from 'react';

class App extends React.Component {


  componentDidMount() {
    if (typeof window !== 'undefined') {
    window.location.href = "https://www.peerspace.com/pages/listings/61039ef60b4b6b000c8ba3cb?utm_source=copy_link&utm_campaign=listing_sharing";
}
  }

  render() {

    return (
      <div className="App">
      </div>
    );
  }
}

export default App;